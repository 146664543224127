import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../Services/user.service';
import { emitLocationInfo } from '../Store/features/user.slice';

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const usePosition = (watch = false, userSettings = {}) => {

  const role = useSelector(state => state?.user?.role);

  const isLoggedIn = role == 'USER';
  const settings = {
    ...defaultSettings,
    ...userSettings,
  };

  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    bindLocation();
  }, [])
  useEffect(() => {
    if (isLoggedIn && position) {
      setGeoLocation();
    }
  }, [isLoggedIn, position])

  const setGeoLocation = async () => {
    try {
      await UserService.setGeoLocation({ ...position });
      dispatch(emitLocationInfo())
    } catch (error) {
      console.log(error)
    }
  }

  const bindLocation = async () => {
    navigator.geolocation.getCurrentPosition(async ({ coords }) => {
      const locationInfo = await UserService.getPLaceInfo(coords.latitude, coords.longitude);
      let lInfo;
      if (locationInfo?.data?.results && locationInfo?.data?.results?.length > 0) {
        lInfo = locationInfo?.data?.results[0].address_components?.reduce((a, b) => {
          if (b?.types?.includes('sublocality_level_2') && !a.subLocality) {
            a.subLocality = b?.long_name;
          }
          if (b?.types?.includes('sublocality_level_1') && !a.locality) {
            a.locality = b?.long_name;
          }
          if (b?.types?.includes('locality') && !a.city) {
            a.city = b?.long_name;
          }
          if (b?.types?.includes('administrative_area_level_1') && !a.state) {
            a.state = b?.long_name;
          }
          if (b?.types?.includes('country') && !a.country) {
            a.country = b?.long_name;
          }
          return a
        }, {
          subLocality: null,
          city: null,
          state: null,
          country: null,
          locality: null
        })
      };
      setPosition({ latitude: String(coords?.latitude), longitude: String(coords?.longitude), ...lInfo });

    }, onError, settings);
  }

  const onChange = async ({ coords, timestamp }) => {
    console.log(coords)
    setPosition({
      latitude: String(coords?.latitude),
      longitude: String(coords?.longitude)
    })
  };

  const onError = (error) => {
    setError(error.message);
  };

  return { position, error };
};