const TokenService = {
  
    getLocalAccessToken() {
      return localStorage.getItem("token")
    },
  
    setToken(token) {
      localStorage.setItem("token", token);
    },

    removeUser() {
      window.localStorage.removeItem("user");
    }

  }
  
  export default TokenService;