import './App.scss';
import { Suspense } from 'react';
import { routes } from './Routes/Routes';
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { io } from 'socket.io-client';
import { usePosition } from './Hooks/usePosition';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export let socket = io.connect("https://api.flaplive.com/chat");

function App() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const { error } = usePosition();

  const actions = useSelector((state) => {
    console.log(state?.user?.actions);
    return state?.user?.actions || [];
  });

  return (
    <Suspense fallback={ <div style={{
      display: 'flex', alignItems: 'center',
      justifyContent: 'center', height: '100vh', width: '100vw'
    }}>
     
        <img src="/assets/images/loading.png" style={{width:matches ? 140 : 200 }} className='rotating' />
    </div>}>
      <ToastContainer />
      {useRoutes(routes({ actions }))}
    </Suspense>

  );
}

export default App;
