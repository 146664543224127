import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// Auth Module
const AuthLayout = lazy(() => import('../Modules/Auth/Auth'));
const LoginLayout = lazy(() => import('../Modules/Auth/Login/Login'));
const SignupLayout = lazy(() => import('../Modules/Auth/Signup/Signup'));

const UserLayout = lazy(() => import('../Modules/User/User'));
const FeedsLayout = lazy(() => import('../Modules/User/Feeds/Feeds'));
const FlapcoinsLayout = lazy(() => import('../Modules/User/Flapcoins/Flapcoins'));
const ChatLayout = lazy(() => import('../Modules/User/Chat/Chat'));
const PurchasedVideosLayout = lazy(() => import('../Modules/User/News/PurchasedVideos/PurchasedVideos'));
const FollowersLayout = lazy(() => import('../Modules/User/Followers/Followers'));
const HistoryLayout = lazy(() => import('../Modules/User/History/History'));
const PaymentSuceessLayout = lazy(() => import('../Modules/User/PaymentSuccess/PaymentSuccess'));
const PaymentFailureLayout = lazy(() => import('../Modules/User/PaymentFailure/PaymentFailure'));
const MyVideosLayout = lazy(() => import('../Modules/User/News/MyVideos/MyVideos'));
const DeleteAccountLayout = lazy(() => import('../Modules/User/DeleteAccount/DeleteAccount'));

// News Module
const NewsLayout = lazy(() => import('../Modules/User/News/News'));
const SearchResultsLayout = lazy(() => import('../Modules/User/SearchResults/SearchResults'));
const HomeLayout = lazy(() => import('../Modules/User/News/Home/Home'));
const WatchNewsLayout = lazy(() => import('../Modules/User/News/WatchNews/WatchNews'));
const ShootsLayout = lazy(() => import('../Modules/User/News/Shoots/Shoots'));
const TrendingLayout = lazy(() => import('../Modules/User/News/Trending/Trending'));
const ContentModerationPolicyLayout = lazy(() => import('../Modules/User/News/ContentModerationPolicy/ContentModerationPolicy'));
const TermsAndConditionsLayout = lazy(() => import('../Modules/User/News/TermsAndConditions/TermsAndConditions'));
const RefundCancellationPolicyLayout = lazy(() => import('../Modules/User/News/RefundCancellationPolicy/RefundCancellationPolicy'));
const VideoPrivacyPolicyLayout = lazy(() => import('../Modules/User/News/VideoPrivacyPolicy/VideoPrivacyPolicy'));
const PrivacyPolicyLayout = lazy(() => import('../Modules/User/News/PrivacyPolicy/PrivacyPolicy'));

// User Channel Module 
const UserChannelLayout = lazy(() => import('../Modules/User/UserChannel/UserChannel'));

// Client Module
const ClientLayout = lazy(() => import('../Modules/Client/Client'));
const AgentsLayout = lazy(() => import('../Modules/Client/Agents/Agents'));
const AddAgentLayout = lazy(() => import('../Modules/Client/Agents/AddAgent/AddAgent'));
const AgentFeedLayout = lazy(() => import('../Modules/Client/AgentFeed/AgentFeed'));
const ContestsLayout = lazy(() => import('../Modules/Client/Contests/Contests'));
const SubscriptionsLayout = lazy(() => import('../Modules/Client/Subscriptions/Subscriptions'));
const CLientFollowersLayout = lazy(() => import('../Modules/Client/ClientFollowers/ClientFollowers'));
const ClientSupportLayout = lazy(() => import('../Modules/Client/ClientSupport/ClientSupport'));
const OverviewLayout = lazy(() => import('../Modules/Client/Overview/Overview'));
const FlapliveLayout = lazy(() => import('../Modules/Client/Flaplive/Flaplive'));
const ClientMyVideosLayout = lazy(() => import('../Modules/Client/ClientMyVideos/ClientMyVideos'));
const ClientHistoryLayout = lazy(() => import('../Modules/Client/ClientHistory/ClientHistory'));
const ClientPlaylistLayout = lazy(() => import('../Modules/Client/ClientPlaylist/ClientPlaylist'));


export const routes = ({ actions, role }) => (
    [
        {
            path: '/',
            element: (<>
                <NewsLayout />
            </>),
            children: [
                {
                    index: true,
                    element: <HomeLayout />
                },
                {
                    path: 'search-results',
                    element: <SearchResultsLayout />
                },
                {
                    path: 'content-policy',
                    element: <ContentModerationPolicyLayout />
                },
                {
                    path: 'terms',
                    element: <TermsAndConditionsLayout />
                },
                {
                    path: 'refund-policy',
                    element: <RefundCancellationPolicyLayout />
                },
                {
                    path: 'video-privacy-policy',
                    element: <VideoPrivacyPolicyLayout />
                },
                {
                    path: 'privacy-policy',
                    element: <PrivacyPolicyLayout />
                },
                {
                    path: 'trending',
                    element: <TrendingLayout />
                },
                {
                    path: 'shoots',
                    element: <ShootsLayout />
                },
                {
                    path: 'shoots/:id',
                    element: <ShootsLayout />
                },
                {
                    path: 'watch/:id',
                    element: <WatchNewsLayout />
                },

            ]
        },
        {
            path: 'search-results-mobile',
            element: <SearchResultsLayout />
        },
        {
            path: '/auth',
            element: (<AuthLayout />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/login" />
                },
                {
                    path: 'login',
                    element: <LoginLayout />
                },
                {
                    path: 'signup',
                    element: <SignupLayout />
                }
            ]
        },
        {
            path: '/client',
            element: (<ClientLayout />),
            children: [
            {
                index: true,
                element: <Navigate to="/client/overview" />
            },
            {
                path: 'overview',
                element: <PrivateRoute actions={actions} component={OverviewLayout} />
            },
            {
                path: 'flaplive',
                element: <PrivateRoute actions={actions} component={FlapliveLayout} />
            },
            {
                path: 'agents',
                element: <PrivateRoute actions={actions} component={AgentsLayout} />
            },

            {
                path: 'agent-feed',
                element: <PrivateRoute actions={actions} component={AgentFeedLayout} />
            },
            {
                path: 'add-agent',
                element: <PrivateRoute actions={actions} component={AddAgentLayout} />
            },
            {
                path: 'contests',
                element: <PrivateRoute actions={actions} component={ContestsLayout} />
            },
            {
                path: 'subscriptions',
                element: <PrivateRoute actions={actions} component={SubscriptionsLayout} />
            },     
            {
                path: 'followers',
                element: <PrivateRoute actions={actions} component={CLientFollowersLayout} />
            },
            {
                path: 'support',
                element: <PrivateRoute actions={actions} component={ClientSupportLayout} />
            },
            {
                path: 'my-videos',
                element: <PrivateRoute actions={actions} component={ClientMyVideosLayout} />
            },
            {
                path: 'history',
                element: <PrivateRoute actions={actions} component={ClientHistoryLayout} />
            },
            {
                path: 'playlist',
                element: <PrivateRoute actions={actions} component={ClientPlaylistLayout} />
            },
        ]
        },

        {
            path: '/user',
            element: (<UserLayout />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/user/news" />
                },
                {
                    path: 'flapcoins',
                    element: <PrivateRoute actions={actions} component={FlapcoinsLayout} />
                },
                {
                    path: 'followers',
                    element: <PrivateRoute actions={actions} component={FollowersLayout} />
                },
                {
                    path: 'history',
                    element: <PrivateRoute actions={actions} component={HistoryLayout} />
                },
                {
                    path: 'feed',
                    element: <PrivateRoute actions={actions} component={FeedsLayout} />
                },
                {
                    path: 'feed/:id',
                    element: <PrivateRoute actions={actions} component={FeedsLayout} />
                },
                {
                    path: 'messages',
                    element: <PrivateRoute actions={actions} component={ChatLayout} />
                },
              
                {
                    path: 'my-videos',
                    element: <PrivateRoute actions={actions} component={MyVideosLayout} />
                },
                {
                    path: 'purchased-videos',
                    element: <PrivateRoute actions={actions} component={PurchasedVideosLayout} />
                },

                {
                    path: 'channel/:id',
                    element: (<UserChannelLayout />),

                },
                {
                    path: 'channel',
                    element: (<PrivateRoute actions={actions} component={UserChannelLayout} />),

                },
                {
                    path: 'delete',
                    element: (<PrivateRoute actions={actions} component={DeleteAccountLayout} />),

                },
                {
                    path: 'payment-success',
                    element: (<PrivateRoute actions={actions} component={PaymentSuceessLayout} />),

                },
                {
                    path: 'payment-failed',
                    element: (<PrivateRoute actions={actions} component={PaymentFailureLayout} />),

                },
               
            ]
        },
        {
            path: '*',
            element: <Navigate to="/" />
        }
    ]
)
