import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import setupInterceptors from "./Services/setupInterceptors";
import { store } from './Store';
import { Provider } from "react-redux";
import { Buffer } from 'buffer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DownloadAppScreen from './Components/DownloadAppScreen/DownloadAppScreen';

global.Buffer = Buffer;

function getPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
      return "ANDROID";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
  }
  return "OTHER";
}

const disableConsoleLogs = () => {
  console.log = console.warn = console.error = () => { };
};

// disableConsoleLogs();

setupInterceptors();

const platform = getPlatform();

ReactDOM.render(platform === 'OTHER' ? <React.StrictMode>
  <Provider store={store} >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
</React.StrictMode> : (<React.StrictMode>
  <DownloadAppScreen platform={platform} />
</React.StrictMode>), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
