import axios from 'axios';
import api from './api';

const UserService = {

    // Channel
    createChannel(payload) {
        return api.post('/channel/v1/create', payload, { headers: { 'content-type': 'multipart/form-data' } })
    },

    requestVerification(payload) {
        return api.post('/flapper-verification', payload, { headers: { 'content-type': 'multipart/form-data' } })
    },
    
    hasVerification() {
        return api.get('/flapper-verification/find-by-user' )
    },

    didUserHasChannel() {
        return api.get('/channel/v1/me', { headers: { hideLoader: true } })
    },

    createFeed(payload) {
        return api.post('/feed', payload, { headers: { 'content-type': 'multipart/form-data' } })
    },

    getAllFeed() {
        return api.get('/feed')
    },

    getFeed(id) {
        return api.get(`/feed/${id}`)
    },

    createPost(payload) {
        return api.post(`/feed`, payload)
    },

    getUserinfo() {
        return api.get("/auth/me", { headers: { hideLoader: true } });
    },

    createLike(payload) {
        return api.post(`/feed/like`, payload, { headers: { hideLoader: true } })
    },

    createDisLike(payload) {
        return api.post(`/news/dislike`, payload, { headers: { hideLoader: true } })
    },

    createComment(payload) {
        return api.post(`/feed/comment`, payload)
    },

    createVideo(payload, type) {
        return api.post(`/news/${type === 'video' ? 'create' : 'create-shoot'}`, payload, { headers: { 'content-type': 'multipart/form-data', hideLoader: true } })
    },

    changeAmount(payload) {
        return api.post(`/news/change-amount`, payload)
    },

    buyNow(payload) {
        return api.post(`/news/buy-now`, payload)
    },

    getAllNews(payload) {
        return api.post(`/news/mobile/all-news`, payload, { headers: { hideLoader: true } })
    },

    purchasedVideos() {
        return api.get('/news/purchased-videos')
    },

    getMyVideos() {
        return api.get('/news/my-videos')
    },

    getNewsByType(type) {
        return api.get(`/news/type/${type}`)
    },

    getHistory() {
        return api.get('/news/history')
    },

    getNewsBySearch(payload) {
        return api.post(`/news/search`, payload)
    },

    getNewsByFollowers() {
        return api.get(`/news/followers`)
    },

    getAllShoots() {
        return api.get('/news/shoots')
    },

    getAllShootsForUser() {
        return api.get('/news/user/shoots')
    },

    getNews(id) {
        return api.get(`/news/${id}`)
    },
    getNewsForUser(id) {
        return api.get(`/news/user/${id}`)
    },

    createLikeForNews(payload) {
        return api.post(`/news/like`, payload, { headers: { hideLoader: true } })
    },


    createCommentLike(payload) {
        return api.post(`/news/comment/like`, payload, { headers: { hideLoader: true } })
    },

    createCommentForNews(payload) {
        return api.post(`/news/comment`, payload)
    },

    getConvs() {
        return api.get(`/message`)
    },

    markMessageAsRead(payload) {
        return api.post(`/message/mark-as-read`, payload, { headers: { hideLoader: true } })
    },

    getChannelVideos() {
        return api.get('/channel/videos')
    },

    getChannelById(id) {
        return api.get(`/channel/v1/${id}`)
    },


    getChannelVideosById(id) {
        return api.get(`/channel/videos/${id}`)
    },

    followChannel(payload) {
        return api.post(`/channel/follow`, payload)
    },

    isFollowing(id) {
        return api.get(`/channel/isFollowing/${id}`)
    },

    getFollowers() {
        return api.get(`/channel/followers`, { headers: { hideLoader: true } })
    },

    getUserFollowers() {
        return api.get(`/channel/user-followers`)
    },

    getFollowersByChannel(id) {
        return api.get(`/channel/followers/${id}`)
    },

    getFollowersByUserChannel(id) {
        return api.get(`/channel/followers/channel/${id}`)
    },

    getAgentFeed() {
        return api.get(`/news/agent-feed`)
    },

    createContests(payload) {
        return api.post(`/contest`, payload)
    },

    updatePoll(payload) {
        return api.post(`/feed/update-poll`, payload)
    },

    markAsReadNotification(payload) {
        return api.post(`/notification/mark-as-read`, payload)
    },

    getContest(id) {
        return api.get(`/contest/${id}`);
    },

    getContests() {
        return api.get(`/contest`);
    },

    updateContest(payload) {
        return api.patch(`/contest`, payload)
    },

    createSupports(payload) {
        return api.post(`/support`, payload)
    },

    getFlapCoinTransactions() {
        return api.get(`/flap-coin-transactions`, { headers: { hideLoader: true } });
    },

    findAllFlapcoinTransactionsByUser() {
        return api.get(`/flap-coin-transactions/user-transactions`);
    },

    buyCoins(payload) {
        return api.post(`/flap-coin-transactions/buy-coins`, payload);
    },

    buySlaps(payload) {
        return api.post(`/flap-coin-transactions/buy-slaps`, payload);
    },

    getTransaction(id) {
        return api.get(`/flap-coin-transactions/get-transaction/${id}`);
    },

    getPLaceInfo(lat, lng) {
        return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCgFl5TRnURDBSWv60jjICd0BZDIbNiLB8`);
    },

    setGeoLocation(payload) {
        return api.post(`/geo-location/`, payload, { headers: { hideLoader: true } });
    },

    getGeoLocation() {
        return api.get(`/geo-location/me`, { headers: { hideLoader: true } });
    },

    getNotifications() {
        return api.get(`/notification`, { headers: { hideLoader: true } });
    },

    createFlapRoom(payload) {
        return api.post(`/flap-room`, payload)
    },

    serachInfluencers(id) {
        return api.get(`/user/search-influencers/${id}`);
    },

    createView(id) {
        return api.get(`/news/view/${id}`)
    },

    createNetwork(payload) {
        return api.post(`/network`, payload)
    },

    getNetworksByUser() {
        return api.get(`/network/all`);
    },

    createPlaylist(payload) {
        return api.post(`/playlist`, payload)
    },

    getPlaylistsByUser() {
        return api.get(`/playlist/all`);
    },
    getPlaylistVideosByUser() {
        return api.get(`/playlist/videos/all`);
    },

    addVideoToPlaylist(payload) {
        return api.post(`/playlist/add-video`, payload)
    },

    getVideosByPlaylists(id) {
        return api.get(`/playlist/videos/${id}`);
    },

    removeVideosFromPlaylists(id) {
        return api.delete(`/playlist/videos/${id}`);
    },

    createSavedVideo(payload) {
        return api.post(`/saved-video`, payload)
    },

    getSavedVideosByUser() {
        return api.get(`/saved-video/all`);
    },

    removeSavedVideos(id) {
        return api.delete(`/saved-video/videos/${id}`);
    },

    createDownloadedVideo(payload) {
        return api.post(`/downloaded-video`, payload)
    },

    getDownloadedVideosByUser() {
        return api.get(`/downloaded-video/all`);
    },

    blockUserFollowers(id) {
        return api.delete(`/channel/user-followers/block/${id}`)
    },
    deleteAccount() {
        return api.delete(`/user/delete-account`)
    },

    createReport(payload) {
        return api.post(`/report`, payload)
    },

    blockUser(payload) {
        return api.post(`/block-user`, payload)
    },

}

export default UserService;