import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  actions: [],
  name: '',
  avatar: '',
  email: '',
  referralId: '',
  role: '',
  hasFlapper: false,
  id: '',
  locationInfo: '',
  selectedCategory: 'All',
  channelInfo: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addActions(state, action) {
      state.id = action.payload?.id;
      state.actions = action.payload?.actions;
      state.name = action.payload?.name;
      state.email = action.payload?.email;
      state.avatar = action.payload?.avatar;
      state.referralId = action.payload?.referralId;
      state.role = action.payload?.role;
      state.hasFlapper = action.payload?.hasFlapper;
    },
    emitLocationInfo(state, action) {
      state.locationInfo = 'emit'
    },
    resetState(state, action) {
      state = initialState;
    },
    bindSelectedCategory(state, action) {
      state.selectedCategory = action.payload
    },
    bindUserChannel(state, action) {
      state.channelInfo = action.payload
    },
  },
})

export const { addActions, resetState, emitLocationInfo, bindSelectedCategory, bindUserChannel } = userSlice.actions;

export default userSlice.reducer