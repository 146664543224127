import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if(!config.headers['hideLoader']) {
        document.getElementById('loading-indicator').classList.add('show-loader');
      }
        const token = TokenService.getLocalAccessToken();
        console.log(token);
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
      return config;
    },
    (error) => {
      document.getElementById('loading-indicator').classList.remove('show-loader');
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
       document.getElementById('loading-indicator').classList.remove('show-loader');
      return res;
    },
    async (err) => {
       document.getElementById('loading-indicator').classList.remove('show-loader');
      console.log(err);
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          localStorage.clear();
          window.location.href = '/auth/login'
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;